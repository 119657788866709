import FormStorage from 'form-storage';

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

$(document).ready( function () {

    const formStorage = new FormStorage('#f', {
        name: 'form-basic', // you can decide local-storage name
        ignores: [
            '[type="hidden"]',
            '[name="policy"]'
        ]
    });

    formStorage.apply();

    var allowSubmit = false;

    $("#f").submit(function(e){
        if (!allowSubmit) {
            e.preventDefault();
        }
    });

    $('.btn-submit').click(function () {
        let $sakerhetsord = $('#sakerhetsord');
        let $email = $('#email');

        let words = ["åtta", "ÅTTA", "Åtta"];

        if ($email.val().length < 3 || validateEmail($email.val()) === false) {
            $email.addClass('is-invalid');
            $email.focus();
            alert('Du måste ange en korrekt epost.')
            return;
        }
        if (words.indexOf($sakerhetsord.val()) <= -1) {
            $sakerhetsord.addClass('is-invalid');
            $sakerhetsord.focus();
            alert('Fyll i säkerhetsordet. Vad kommer efter sju?')
        } else {
            $('.btn-submit-text').text("Skickar…");
            $(this).attr("disabled", true);
            $('#spinner').removeClass('d-none');
            allowSubmit = true;
            formStorage.clear();
            $("#f").submit();
        }
    });

    $('#f').on('input', function() {
        $('input').each(function(){
            formStorage.save();
        });
    });

    $('.question input').keydown( function() {
        $(this).removeClass('is-invalid');
    });

    $('.btn-next').click(function () {
        let nextQuestion = $(this).data('next');
        let currentQuestion = $(this).data('current');
        let $currentField = $('#question-' + currentQuestion + ' input');

        if ($currentField.prop('required')) {
            if ($currentField.val() === '') {
                $currentField.addClass('is-invalid');
                alert('Obligatoriskt fält');
                return false;
            };
        }

        let $question = $('.question');

        $question.addClass('d-none');
        $question.removeClass('d-flex');
        $('.progress').removeClass('d-none');

        let $progressBar = $('#progress-bar');
        if (nextQuestion === 'last') {
            $progressBar.css('width', '100%');
        } else {
            $progressBar.css('width', (nextQuestion * factor) + '%');
        }

        let $nextQuestion = $('#question-' + nextQuestion);
        $nextQuestion.removeClass('d-none');
        $nextQuestion.addClass('d-flex');

        $("html, body").animate({ scrollTop: 0 }, "slow");

        return false;
    });
});

